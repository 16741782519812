function performAutofillRequest(
  formId,
  clientId,
  usePrevious = false,
  checkStatus = false
) {
  $.ajax({
    url: site_url("clients/forms/autofill"),
    method: "POST",
    data: {
      form_id: formId,
      client_id: clientId,
      use_previous: usePrevious ? 1 : 0,
      check_status: checkStatus ? 1 : 0,
    },
    success: function (response) {
      handleAutofillResponse(response, formId, clientId);
    },
    error: function (xhr, status, error) {
      showError(
        "An error occurred while autofilling the form. Please try again later."
      );
    },
  });
}

function handleAutofillResponse(data, formId, clientId) {
  if (data.status === "generated") {
    fillFormWithData(data.data);
    showCompletionMessage();
    $("#autofill-modal").modal("hide");
  } else if (data.status === "generating") {
    showGeneratingMessage();
    setTimeout(
      () => performAutofillRequest(formId, clientId, true, true),
      5000
    );
  } else if (data.status === "error") {
    showError(data.error || "An unexpected error occurred.");
  } else {
    showError("Unexpected response from server.");
  }
}

$(document).on("click", ".js-autofill-with-ai", function (e) {
  e.preventDefault();
  const usePrevious = $(this).hasClass("js-use-previous");
  const $modal = $("#autofill-modal");
  const formId = $modal.data("form-id");
  const clientId = $modal.data("client-id");

  // Show loading indicator
  $("#autofill-error, #autofill-content").hide();
  $("#autofill-loading").show();
  $(".modal-footer").hide();

  performAutofillRequest(formId, clientId, usePrevious);
});

$(document).on("ready", function () {
  const $modal = $("#autofill-modal");
  const $isGenerating = $modal.find("[name='is_generating']");
  if ($isGenerating.length && parseInt($isGenerating.val()) === 1) {
    showGeneratingMessage();
    performAutofillRequest(
      $modal.data("form-id"),
      $modal.data("client-id"),
      true,
      true
    );
  }
});

function showError(message) {
  $("#autofill-loading, #autofill-content").hide();
  $("#autofill-error").text(message).show();
  $(".modal-footer").show();
}

function showGeneratingMessage() {
  $("#autofill-error, #autofill-content").hide();
  $("#autofill-loading p").html(
    "GreenShoots AI is generating responses based on this client's case notes.<br />This will take a few minutes."
  );
  $("#autofill-loading").show();
  $(".modal-footer").hide();
}

function showCompletionMessage() {
  $("#autofill-loading").hide();
  $("#autofill-content")
    .html(
      "<p>The form has been successfully filled with AI-generated responses.</p>"
    )
    .show();
  $(".modal-footer").show();
}

function fillFormWithData(data) {
  console.log(data, "filling");

  // Iterate through the form fields and fill them with the received data
  $.each(data, function (fieldName, fieldValue) {
    const $field = $('[name="' + fieldName + '"]');
    if ($field.length) {
      if ($field.is(":radio")) {
        $field.filter('[value="' + fieldValue + '"]').prop("checked", true);
      } else if ($field.is(":checkbox")) {
        $field.prop("checked", fieldValue === true);
      } else if ($field.is("select")) {
        $field.val(fieldValue).trigger("change");
      } else {
        $field.val(fieldValue).trigger("input");
      }
      $field.trigger("change");
    }
  });
}
